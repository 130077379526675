<template>
  <div class="discounts-info">
    <div class="nav-box">
      <van-icon
        name="arrow-left"
        size="18"
        color="#6F6F6F"
        class="arrow-left"
        @click="navBack"
      />
      <span>优惠券详情</span>
      <!-- <van-icon name="ellipsis" size="18" color="#6F6F6F" /> -->
    </div>
    <div class="list">
      <!-- 今日到期--img -->
      <img
        src="@/assets/discounts/duetoday@2x.png"
        class="expire-icon"
        v-if="info.is_day === 1 && info.status !== 4"
      />
      <!-- 未生效--img -->
      <img
        src="@/assets/discounts/pulldown_icon.png"
        class="pulldown-icon"
        v-if="info.status === 2"
      />
      <div class="shade" v-if="info.status === 4 || info.status === 3">
        <!-- 已过期--img -->
        <img
          src="@/assets/discounts/expire-tag.png"
          class="expire-tag"
          v-if="info.status === 4"
        />
        <!-- 已使用--img -->
        <img
          src="@/assets/discounts/havebeenused@2x.png"
          class="havebeenused"
          v-if="info.status === 3"
        />
      </div>
      <div class="list-left">
        <div class="list-left-top">
          <div class="discounts">
            {{ info.name }}
          </div>
          <div class="valid-time">
            <span>{{ info.start_time }}</span>
            <span>至</span>
            <span>{{ info.end_time }}</span>
          </div>
        </div>
        <div class="list-left-bottom">使用范围：{{ info.range_str }}</div>
      </div>
      <div class="list-right">
        <div
          class="right-content"
          :class="{ 'right-bottom': status === 4 || status === 3 }"
        >
          <div class="money">
            <Price :price="info.money" integerStyle="28"></Price>
          </div>
          <div class="overbrim-minus">
            {{ info.full_type === 1 ? `满${info.full_money}元可减` : "无门槛" }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="use-btn" v-if="status == 1" @click="useCoupon">立即使用</div>
      <div
        class="use-btn"
        v-else-if="status == 4 || status == 3"
        @click="delDiscount"
      >
        删除
      </div>
    </div>
    <div class="describe">
      <div class="describe-row" v-if="info.desc">
        <div class="describe-title">优惠券描述</div>
        <div class="describe-text">
          {{ info.desc }}
        </div>
      </div>
      <div class="describe-row" v-if="info.no_range_str">
        <div class="describe-title">不可与其他活动共享</div>
        <div class="describe-text">
          {{ info.no_range_str }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDiscountInfo } from "@/api/discounts.js";
import { delDiscount } from "@/api/discounts.js";
import { Toast, Dialog } from "vant";
export default {
  name: "discounts-info",
  data() {
    return {
      status: this.$route.query.status || 2,
      couponId: this.$route.query.couponId || 0,
      grantId: this.$route.query.grantId || 0,
      detailId: this.$route.query.detailId || 0, // 优惠券记录ID
      info: {},
    };
  },
  mounted() {
    this.getDiscountInfo();
  },
  methods: {
    useCoupon() {
      this.$router.push({
        name: "discounts",
        query: {
          couponId: this.couponId,
          // grantId: this.grantId,
          detailId: this.detailId,
        },
      });
    },
    delDiscount() {
      const _that = this;
      Dialog.confirm({
        title: "提示",
        message: "您确认删除此优惠券吗？",
      })
        .then(async () => {
          const res = await delDiscount({ id: this.info.id });
          if (res.code === 1) {
            Toast.success(res.msg);
            setTimeout(() => {
              _that.$router.go(-1);
            }, 500);
          }
        })
        .catch(() => {});
    },
    async getDiscountInfo() {
      const res = await getDiscountInfo({
        status: this.status,
        coupon_id: this.couponId,
        // grant_id: this.grantId,
        id: this.detailId,
      });
      if (res.code === 1) {
        this.info = res.data;
        this.intercept(this.info);
      }
    },
    intercept(data) {
      let ary = [];
      ary = String(data.full_money).split(".");
      if (ary[1] == "00") {
        data.full_money = ary[0];
      } else {
        data.full_money = ary.join(".");
      }
    },
    navBack() {
      localStorage.setItem("couponStatus", this.info.status);
      this.$router.go(-1);
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
.nav-box {
  position: relative;
  height: 57px;
  text-align: center;
  line-height: 57px;
  padding: 0 12px;
  background-color: #fff;
  .arrow-left {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  span {
    font-size: 21px;
  }
}
.list {
  position: relative;
  display: flex;
  margin: 14px 13px;
  background: url("../../../assets/discounts/bg@2x.png") no-repeat center / 100%
    100%;
  &:last-child {
    margin-bottom: 0;
  }

  .expire-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 57px;
    height: 57px;
  }
  .pulldown-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 68px;
    height: auto;
  }
  .havebeenused {
    position: absolute;
    right: 0;
    top: 0;
    width: 68px;
    height: auto;
  }
  .shade {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10;
    .expire-tag {
      position: absolute;
      top: 0;
      right: 0;
      width: 68px;
      height: 68px;
    }
  }
}
.list-left {
  flex: 1;
  padding: 17px 14px;
}
.list-left-top {
  padding-bottom: 16px;
  border-bottom: 1px dashed rgb(235, 235, 235);
  .discounts {
    font-size: 17px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
    .tag-green {
      background-color: #59b831;
    }
    .tag-orange {
      background-color: #fda918;
    }
  }
  .valid-time {
    margin-top: 8px;
    color: #999999;
    font-size: 13px;
  }
}
.list-left-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  font-size: 12px;
  color: #666666;
  .describe-content {
    margin-top: 12px;
    .describe-label {
      width: 60px;
    }
  }
}
.list-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .money {
      display: flex;
      align-items: center;
      color: #ed301d;
      font-size: 12px;
      .money-number {
        font-size: 28px;
      }
    }
    .overbrim-minus {
      color: #666666;
      font-size: 13px;
    }
  }
  .right-bottom {
    padding-bottom: 25px;
  }
}
.use-btn {
  height: 52px;
  background: linear-gradient(131deg, #f76354 0%, #f55544 3%, #f0321f 100%);
  border-radius: 52px;
  line-height: 52px;
  color: #fff;
  margin: 0 26px;
  font-size: 17px;
  text-align: center;
}
.describe {
  padding: 21px 15px;
  .describe-row {
    &:nth-child(2) {
      margin-top: 27px;
    }
  }
  .describe-title {
    font-size: 17px;
  }
  .describe-text {
    font-size: 14px;
    color: #666666;
    margin-top: 8px;
  }
}
</style>